<!-- src/App.vue -->
<template>
  <div class="container my-5">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <h1 class="text-center mb-4">EDW JD Level Deductor</h1>
        <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="password"
              required
            />
          </div>
          <div class="mb-3">
            <label for="pdfFile" class="form-label">Archivo PDF</label>
            <input
              type="file"
              class="form-control"
              id="pdfFile"
              @change="handleFileUpload"
              accept="application/pdf"
              required
            />
          </div>
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-secondary"
              @click="resetForm"
              :disabled="isLoading"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading"
            >
              Deducir
            </button>
          </div>
        </form>

        <!-- Indicador de carga -->
        <div v-if="isLoading" class="text-center my-4">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Cargando...</span>
          </div>
          <p class="mt-2">Procesando la información...</p>
        </div>

        <!-- Mostrar la respuesta renderizada como Markdown -->
        <div class="mt-4" v-if="!isLoading && response">
          <h4>Respuesta:</h4>
          <div class="border p-3" style="min-height: 100px;">
            <div v-html="renderedResponse"></div>
          </div>
        </div>

        <!-- Mostrar errores -->
        <div class="mt-4 text-danger" v-if="!isLoading && errorMessage">
          <h4>Error:</h4>
          <div class="border p-3" style="min-height: 100px;">
            <p>{{ errorMessage }}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import axios from 'axios';
import { marked } from 'marked'; // Importamos la librería marked
import DOMPurify from 'dompurify'; // Importamos DOMPurify para sanitizar el HTML

const password = ref('');
const pdfFile = ref(null);
const response = ref('');
const errorMessage = ref('');
const isLoading = ref(false);

// Computed property para renderizar la respuesta Markdown a HTML seguro
const renderedResponse = computed(() => {
  const dirtyHTML = marked.parse(response.value || '');
  const cleanHTML = DOMPurify.sanitize(dirtyHTML);
  return cleanHTML;
});

const handleFileUpload = (event) => {
  pdfFile.value = event.target.files[0];
};

const resetForm = () => {
  password.value = '';
  pdfFile.value = null;
  response.value = '';
  errorMessage.value = '';
  isLoading.value = false;
  document.getElementById('pdfFile').value = null;
};

const handleSubmit = async () => {
  if (!password.value || !pdfFile.value) {
    alert('Por favor, completa todos los campos.');
    return;
  }

  isLoading.value = true;
  response.value = '';
  errorMessage.value = '';

  try {
    // Crear FormData para enviar en la solicitud POST
    const formData = new FormData();
    formData.append('file', pdfFile.value);
    formData.append('password', password.value);

    // Hacer la solicitud POST al endpoint /process_pdf
    const pdfResponse = await axios.post(
      'http://localhost:8111/process_pdf',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    // Mostrar la respuesta en el área de output
    response.value = pdfResponse.data.response;
  } catch (error) {
    if (error.response) {
      errorMessage.value = error.response.data.detail;
    } else {
      errorMessage.value = 'Error al procesar la solicitud.';
    }
  } finally {
    isLoading.value = false;
  }
};
</script>

<style>
body {
  background-color: #f8f9fa;
}
</style>
